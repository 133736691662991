import { isAgeRestricted } from '#imports'

const getNavigation = (dateOfBirth: string, requiredAge: string) =>
  isAgeRestricted(dateOfBirth, requiredAge) && '/age-restriction'

export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.server) return
  const { isLoggedIn } = useAuth()
  if (!isLoggedIn.value) return

  const payload = await useUserDataLazyQuery({
    fetchPolicy: 'cache-first',
  }).load()

  if (!payload || !payload?.player) {
    const { log } = useDatadog()
    log('info', {
      component: 'age-verification.ts',
      message: 'Payload or player is missing',
    })
    return
  }

  const {
    i18nConfig: { requiredAge },
  } = useRuntimeConfig().public

  const nuxtApp = useNuxtApp()
  const { dateOfBirth } = payload.player

  const path = getNavigation(dateOfBirth, requiredAge)
  if (!path) return

  if (nuxtApp.isHydrating) {
    onNuxtReady(() => navigateTo(path))
  } else {
    return path
  }
})
